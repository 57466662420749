import React from "react";

import "./button.scss";

interface ButtonProps {
  text: React.ReactNode;
  ariaLabel: string;
  type?: "primary" | "secondary";
  style?: object;
  onClick?: () => void;
}

const Button = ({
  text,
  type = "primary",
  style,
  onClick,
  ariaLabel,
}: ButtonProps) => {
  return (
    <button
      className={`btn btn-${type}`}
      style={style}
      onClick={onClick}
      aria-label={ariaLabel}
    >
      {text}
    </button>
  );
};

export default Button;

import React, { useContext } from "react";
import { Context } from "../context/store";

import "./loading.scss";

interface LoadingProps {
  text: string;
  style?: object;
}

export const Loading = ({ style, text }: LoadingProps) => {
  const { state } = useContext(Context);

  return (
    <div className="loading-container" style={style}>
      <svg
        version="1.1"
        id="loader-1"
        x="0px"
        y="0px"
        width="18rem"
        height="18rem"
        viewBox="0 0 50 50"
      >
        <path
          fill={
            state.theme === "light" ? "hsl(210, 24%, 16%)" : "hsl(210, 16%, 82%"
          }
          d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
        >
          <animateTransform
            attributeType="xml"
            attributeName="transform"
            type="rotate"
            from="0 25 25"
            to="360 25 25"
            dur="0.6s"
            repeatCount="indefinite"
          ></animateTransform>
        </path>
      </svg>
      <p>{text}</p>
    </div>
  );
};

import React, { useContext, useEffect, useRef } from "react";
import { faYelp } from "@fortawesome/free-brands-svg-icons";

import "./card.scss";
import Image from "./image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Context } from "../context/store";
import useOnScreen from "../hooks/useOnScreen";

export interface RestaurantInfo {
  address: string;
  categories: string[];
  distance: string;
  lat: string;
  long: string;
  image: string;
  name: string;
  rating: number;
  transactions: string[];
  url: string;
  yelp_id: string;
  review_count: number;
}

interface CardProps {
  restaurant: RestaurantInfo;
  index: number;
  currentIndex: boolean;
  onOffScreen?: (index: number) => void;
}

const getRatingImage = (rating: number) => {
  let ratingNumberString = Number.isInteger(rating)
    ? rating.toString()
    : `${Math.floor(rating)}_half`;
  return `extra_large_${ratingNumberString}.png`;
};

export const RestaurantCard = ({
  restaurant,
  currentIndex,
  onOffScreen,
  index,
}: CardProps) => {
  const { state } = useContext(Context);
  const ref = useRef();
  const isVisible = useOnScreen(ref);

  useEffect(() => {
    if (!isVisible) {
      onOffScreen?.(index);
    }
  }, [isVisible]);

  return (
    <div
      ref={ref}
      className="card"
      style={{
        boxShadow: currentIndex ? "0px 20px 37px 3px rgba(0, 0, 0, 0.1)" : "",
        transform: currentIndex ? "scale(1)" : "scale(.95)",
        backgroundColor:
          state.theme === "light" ? "white" : "hsl(209, 18%, 30%)",
        zIndex: currentIndex ? 5 : 0,
      }}
    >
      <div
        className="restaurant-image"
        style={{ backgroundImage: `url(${restaurant.image})` }}
      />
      <div className="restaurant-info">
        <div className="restaurant-header">
          <p className="restaurant-name">{restaurant.name}</p>
          <p
            className="restaurant-distance"
            style={{
              color:
                state.theme === "light"
                  ? "hsl(209, 18%, 30%)"
                  : "hsl(210, 16%, 82%)",
            }}
          >
            {(Number(restaurant.distance) * 0.000621371192).toFixed(1)} miles
          </p>
        </div>
        <p
          className="restaurant-categories"
          style={{
            color:
              state.theme === "light"
                ? "hsl(209, 18%, 30%)"
                : "hsl(210, 16%, 82%)",
          }}
        >
          {restaurant.categories[0]}
        </p>
        <div className="yelp-container">
          <div className="rating-container">
            <Image
              className="rating"
              alt={`${restaurant.rating} star restaurant rating from Yelp`}
              source={getRatingImage(restaurant.rating)}
            />
            <p>{restaurant.review_count?.toString()} reviews</p>
          </div>
          <a
            href={restaurant.url}
            target="_blank"
            style={{ color: "#d32323" }}
            aria-label={`${restaurant.name} yelp page`}
            rel="noopener"
          >
            <FontAwesomeIcon
              icon={faYelp}
              style={{ width: "2rem", height: "2rem" }}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

import React, { useContext } from "react";
import { Context } from "../context/store";

import "./alertPage.scss";

interface AlertPageProps {
  text: string;
  style?: object;
}

export const AlertPage = ({ style, text }: AlertPageProps) => {
  const { state } = useContext(Context);

  return (
    <div className="alert-page-container" style={style}>
      <svg viewBox="0 0 679.95 692.61">
        <defs></defs>
        <title>drawkit-fast-food-colour</title>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Vector">
            <path
              className={state.theme === "light" ? "cls-1" : "cls-1-dark"}
              d="M521.27,166.78c67,23.53,141.71,70.6,156,131.34,14.31,60.56-31.77,135-79.83,198.65-48.22,63.53-98.42,116.52-164.42,151.58-66,35.22-147.79,52.83-234.53,40.16C111.79,676,20,633.21,3,565.89c-16.79-67.48,41.31-159.64,64.85-251.81s12.34-184.33,51.34-213C158,72.64,246.91,107.86,321,125.47S454.29,143.24,521.27,166.78Z"
            />
            <polyline
              className={state.theme === "light" ? "cls-2" : "cls-2-dark"}
              points="566.07 177.88 500.94 627.08 341.86 627.08 276.73 177.88"
            />
            <path
              className="cls-3"
              d="M407.41,572.67c.11,1.16.17,2.34.17,3.53v15.22A36.72,36.72,0,0,1,371,628H73a36.72,36.72,0,0,1-36.62-36.61V576.2a34.34,34.34,0,0,1,.17-3.53"
            />
            <rect
              className="cls-4"
              x="36.37"
              y="537.71"
              width="371.21"
              height="42.17"
              rx="11.74"
              ry="11.74"
            />
            <path
              className="cls-4"
              d="M33.4,493.15v28.12c0,6.6,5.14,12,11.41,12H399.14c6.27,0,11.4-5.4,11.4-12V493.15Z"
            />
            <path
              className="cls-3"
              d="M406.47,408.88c0-56.89-82.6-103-184.5-103S37.47,352,37.47,408.88v26.38a12.66,12.66,0,0,0,12.62,12.62H393.85a12.65,12.65,0,0,0,12.62-12.62Z"
            />
            <path
              className="cls-5"
              d="M46.15,469c-21.3,0-30.62,11.17-30.62,21.31,0,14.38,9.28,20.14,21.94,20.14,17.23,0,23-12.3,38.58-12.3,20.18,0,22.9,12.08,40.78,12.08,18.39,0,36.23-15.18,50.89-15.18,20.46,0,35.13,16.26,55.58,16.26s34.09-14.86,54.55-14.86c14.66,0,23.52,14.86,41.92,14.86,17.88,0,23.6-13,43.77-13,15.58,0,25.71,11.27,42.93,11.27,12.67,0,21.57-4.9,21.57-19.28,0-10.14-9.31-21.31-30.61-21.31"
            />
            <path
              className="cls-6"
              d="M367.91,542.76h28.33a3.91,3.91,0,0,0,3.91-3.91v-1.67a3.91,3.91,0,0,0-3.91-3.91H47.71a3.91,3.91,0,0,0-3.91,3.91v1.67a3.91,3.91,0,0,0,3.91,3.91h68.61c16.56,0,19.07,16,19.07,21.52v57.06a5.07,5.07,0,0,0,5,5.13h0a5.07,5.07,0,0,0,5-5.13V564.28c0-23.73,12.82-23.73,12.82,0v10.66a5.07,5.07,0,0,0,5,5.14h0a5.07,5.07,0,0,0,5-5.14V564.28c0-5.57,2.52-21.52,19.07-21.52H307.92c16.55,0,19.06,16,19.06,21.52v31.6a11.15,11.15,0,0,0,10.93,11.29h0a11.15,11.15,0,0,0,10.93-11.29v-31.6C348.84,558.71,351.35,542.76,367.91,542.76Z"
            />
            <rect
              className="cls-7"
              x="46.52"
              y="447.88"
              width="350.91"
              height="32.31"
              rx="5.87"
              ry="5.87"
            />
            <rect
              className={state.theme === "light" ? "cls-2" : "cls-2-dark"}
              x="265.84"
              y="159.68"
              width="311.11"
              height="16.12"
              rx="8.06"
              ry="8.06"
            />
            <rect
              className={state.theme === "light" ? "cls-2" : "cls-2-dark"}
              x="418.58"
              y="66.14"
              width="13.02"
              height="93.54"
            />
            <rect
              className={state.theme === "light" ? "cls-2" : "cls-2-dark"}
              x="387.42"
              y="-9.1"
              width="13.02"
              height="93.54"
              transform="translate(88.74 289.58) rotate(-45)"
            />
            <circle className="cls-7" cx="91.71" cy="405.85" r="3.37" />
            <circle className="cls-7" cx="138.22" cy="378.64" r="3.37" />
            <circle className="cls-7" cx="274.76" cy="362.74" r="3.37" />
            <circle className="cls-7" cx="138.22" cy="340.16" r="3.37" />
            <circle className="cls-7" cx="160.24" cy="412.58" r="3.37" />
            <circle className="cls-7" cx="268.03" cy="415.95" r="3.37" />
            <circle className="cls-7" cx="367.91" cy="425.27" r="3.37" />
            <circle className="cls-7" cx="209.16" cy="378.64" r="3.37" />
            <circle className="cls-7" cx="327.75" cy="362.74" r="3.37" />
            <circle className="cls-7" cx="208.43" cy="340.16" r="3.37" />
          </g>
        </g>
      </svg>
      <p>{text}</p>
    </div>
  );
};

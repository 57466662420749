import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.com/docs/use-static-query/
 */

interface ImageProps {
  className: string;
  source: string;
  alt: string;
}

const Image = ({ className, source, alt }: ImageProps) => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              fluid(maxWidth: 800, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const image = data.images.edges.find(
    (n: { node: { relativePath: string | string[] } }) => {
      return n.node.relativePath.includes(source);
    }
  );

  if (!image) {
    return <div>Picture not found</div>;
  }

  return (
    <Img
      className={className}
      fluid={image.node.childImageSharp.fluid}
      alt={alt}
    />
  );
};

export default Image;

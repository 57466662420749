import React, { useEffect, useState, useMemo, useContext } from "react";
import SEO from "../components/seo";
import { RestaurantCard, RestaurantInfo } from "../components/card";
import TinderCard from "../components/TinderCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faHeart,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import Button from "../components/button";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useCookies } from "react-cookie";
import { Loading } from "../components/loading";
import Collapsible from "react-collapsible";

import "react-toastify/dist/ReactToastify.css";
import "./session.scss";
import { Context } from "../context/store";
import { AlertPage } from "../components/alertPage";

interface SesionProps {
  location: { pathname: string };
}

const GET_GROUP_ID = gql`
  query getGroupId($token: Int) {
    groups(where: { token: { _eq: $token } }) {
      id
    }
  }
`;

const CREATE_SESSION = gql`
  mutation createSession($group_id: Int) {
    insert_sessions(objects: { group_id: $group_id }) {
      returning {
        id
        current_page
        restaurants {
          address
          categories
          distance
          image
          lat
          long
          name
          rating
          transactions
          url
          yelp_id
          review_count
        }
      }
    }
  }
`;

const GET_SESSION = gql`
  query getSession($id: Int) {
    sessions(where: { id: { _eq: $id } }) {
      id
      group_id
      current_page
      restaurants {
        address
        categories
        distance
        image
        lat
        long
        name
        rating
        transactions
        url
        yelp_id
        review_count
      }
    }
  }
`;

const INSERT_SWIPE = gql`
  mutation insertSwipe(
    $group_id: Int
    $lat: String
    $long: String
    $name: String
    $yelp_id: String
  ) {
    insert_swipes(
      objects: {
        name: $name
        long: $long
        lat: $lat
        group_id: $group_id
        yelp_id: $yelp_id
      }
    ) {
      affected_rows
    }
  }
`;

const GET_MATCHES = gql`
  query getMatches($group_id: Int) {
    matches(where: { group_id: { _eq: $group_id } }) {
      swipe {
        name
        lat
        long
        yelp_id
      }
    }
  }
`;

const NEXT_PAGE = gql`
  mutation getNextPage($id: Int) {
    update_sessions(where: { id: { _eq: $id } }, _inc: { current_page: 1 }) {
      returning {
        restaurants {
          address
          categories
          distance
          image
          lat
          long
          name
          rating
          transactions
          url
          yelp_id
          review_count
        }
      }
    }
  }
`;

const customId = "custom-id-yes";

interface Match {
  name: string;
  lat: string;
  long: string;
  yelp_id: string;
}

const session = ({ location }: SesionProps) => {
  const [isInvalidToken, setIsInvalidToken] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [restaurants, setRestaurants] = useState<RestaurantInfo[]>([]);
  const [sessionToken, setSetssionToken] = useState(0);
  const [groupId, setGroupId] = useState(-1);
  const [sessionId, setSessionId] = useState(-1);
  const [cookies, setCookie] = useCookies();
  const [matches, setMatches] = useState<Match[]>([]);

  const { state } = useContext(Context);

  const [
    getGroupId,
    { data: group, loading: groupLoading, error: groupError },
  ] = useLazyQuery(GET_GROUP_ID);
  const [
    createSession,
    {
      data: session,
      loading: sessionLoading,
      error: sessionError,
      called: createdSessionCalled,
    },
  ] = useMutation(CREATE_SESSION);
  const [
    getSessionFromCookie,
    {
      data: sessionCookie,
      error: sessionCookieError,
      loading: sessionCookieLoading,
      called: sessionCookieCalled,
    },
  ] = useLazyQuery(GET_SESSION);
  const [insertSwipe] = useMutation(INSERT_SWIPE);
  const { data: matchesData, stopPolling } = useQuery(GET_MATCHES, {
    variables: { group_id: groupId },
    skip: groupId < 0,
    pollInterval: 1000,
  });
  const [
    nextPage,
    { data: nextSession, loading: nextPageLoading },
  ] = useMutation(NEXT_PAGE);

  const childRefs = useMemo(() => {
    if (session) {
      return Array(session.insert_sessions?.returning[0].restaurants.length)
        .fill(0)
        .map(i => React.createRef());
    } else if (sessionCookie) {
      return Array(sessionCookie.sessions[0]?.restaurants.length)
        .fill(0)
        .map(i => React.createRef());
    } else if (nextSession) {
      return Array(nextSession.update_sessions?.returning[0].restaurants.length)
        .fill(0)
        .map(i => React.createRef());
    }
  }, [session, sessionCookie]);

  useEffect(() => {
    if (nextSession) {
      setRestaurants(nextSession.update_sessions?.returning[0].restaurants);
      setCurrentIndex(
        Math.max(
          nextSession.update_sessions?.returning[0].restaurants.length - 1,
          0
        )
      );
    }
  }, [nextSession]);

  useEffect(() => {
    if (currentIndex < 0) {
      nextPage({ variables: { id: sessionId } });
    }
  }, [currentIndex]);

  useEffect(() => {
    if (matches?.length > 0) {
      toast.info(
        <div>
          <div style={{ fontSize: "18px" }}>New matches!</div>
          <div style={{ fontSize: "14px", color: "#121212" }}>
            Scroll down to see 🍖
          </div>
        </div>,
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          toastId: customId,
        }
      );
    }
  }, [matches]);

  useEffect(() => {
    if (session) {
      setSessionId(session.insert_sessions?.returning[0].id);
      setRestaurants(session.insert_sessions?.returning[0].restaurants);
      setCurrentIndex(
        Math.max(
          session.insert_sessions?.returning[0].restaurants.length - 1,
          0
        )
      );
      setCookie(
        sessionToken.toString(),
        session.insert_sessions?.returning[0].id,
        {
          path: `/session/${sessionToken}`,
        }
      );
    }
  }, [session]);

  useEffect(() => {
    let newMatches = matchesData?.matches?.map((match: { swipe: Match }) => {
      return {
        name: match.swipe.name,
        lat: match.swipe.lat,
        long: match.swipe.long,
        yelp_id: match.swipe.yelp_id,
      };
    });
    setMatches(newMatches);
  }, [matchesData]);

  useEffect(() => {
    if (groupId > 0 && !sessionCookie) {
      createSession({ variables: { group_id: groupId } });
    }
  }, [groupId]);

  useEffect(() => {
    if (group) {
      if (group?.groups[0]?.id) {
        setGroupId(group?.groups[0]?.id);
      } else {
        setGroupId(0);
        setSessionId(-2);
      }
    }
  }, [group]);

  useEffect(() => {
    if (sessionCookie?.sessions[0]) {
      setSessionId(sessionCookie.sessions[0].id);
      setRestaurants(sessionCookie.sessions[0].restaurants);
      setCurrentIndex(
        Math.max(sessionCookie.sessions[0].restaurants.length - 1, 0)
      );
      setGroupId(sessionCookie.sessions[0].group_id);
    } else if (sessionCookie) {
      setGroupId(0);
      setSessionId(-2);
    }
  }, [sessionCookie]);

  useEffect(() => {
    let st = location.pathname.slice(9);
    if (st.length !== 6) {
      setIsInvalidToken(true);
      return;
    }
    let stn = Number(st);
    if (isNaN(stn)) {
      setIsInvalidToken(true);
      return;
    }
    setSetssionToken(stn);

    let sessionId = cookies[stn.toString()];
    if (sessionId) {
      getSessionFromCookie({ variables: { id: sessionId } });
    } else {
      getGroupId({ variables: { token: stn } });
    }
    return () => {
      stopPolling();
    };
  }, []);

  const swipe = (dir: string, index: number) => {
    let restaurant = restaurants[index];
    if (dir === "right" || dir === "left") {
      if (dir === "right") {
        insertSwipe({
          variables: {
            group_id: groupId,
            lat: restaurant.lat,
            long: restaurant.long,
            name: restaurant.name,
            yelp_id: restaurant.yelp_id,
          },
        });
      }
      setCurrentIndex(index - 1);
    }
  };

  const buttonSwipe = (dir: string) => {
    if (currentIndex >= 0) {
      childRefs?.[currentIndex]?.current?.swipe(dir);
    }
  };

  const onOffScreen = (index: number) => {
    let restaurantState = restaurants.filter((_, i) => i !== index);
    setRestaurants(restaurantState);
  };

  if (groupError || sessionError) {
    return (
      <section className="main-container">
        <SEO
          title="Error"
          lang="en"
          meta={[
            {
              name: "viewport",
              content: "width=device-width, initial-scale=1.0",
            },
          ]}
        />
        <AlertPage
          style={{
            maxWidth: "480px",
            width: "100%",
            height: "100%",
            marginTop: "2rem",
          }}
          text="Error joining group. Please try again later."
        ></AlertPage>
      </section>
    );
  }

  if (groupLoading || groupId === -1) {
    return (
      <section className="main-container">
        <Loading
          style={{
            position: "absolute",
            width: "100%",
          }}
          text="Joining the group"
        ></Loading>
      </section>
    );
  }

  if (sessionId === -2) {
    return (
      <section className="main-container">
        <SEO
          title="Incorrect room code"
          lang="en"
          meta={[
            {
              name: "viewport",
              content: "width=device-width, initial-scale=1.0",
            },
          ]}
        />
        <AlertPage
          style={{
            maxWidth: "480px",
            width: "100%",
            height: "100%",
            marginTop: "2rem",
          }}
          text="Incorrect room code!"
        ></AlertPage>
      </section>
    );
  }

  if (sessionLoading || sessionId === -1) {
    return (
      <Loading
        style={{ position: "absolute", width: "100%" }}
        text="Creating a new session"
      ></Loading>
    );
  }

  if (isInvalidToken) {
    return (
      <>
        <SEO
          title="Session"
          lang="en"
          meta={[
            {
              name: "viewport",
              content: "width=device-width, initial-scale=1.0",
            },
          ]}
        />
        <section className="main-container">Invalid Token</section>
      </>
    );
  }

  return (
    <>
      <SEO
        title="Session"
        lang="en"
        meta={[
          {
            name: "viewport",
            content: "width=device-width, initial-scale=1.0",
          },
        ]}
      />
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <section className="main-container session-container">
        <div className="deck-wrapper">
          {nextPageLoading && (
            <Loading
              style={{ position: "absolute", width: "100%", height: "100%" }}
              text="Loading new restaurants"
            ></Loading>
          )}
          {restaurants?.length === 0 &&
            (sessionCookieCalled || createdSessionCalled) &&
            !sessionCookieLoading &&
            !sessionLoading &&
            !nextPageLoading && (
              <AlertPage
                style={{
                  maxWidth: "480px",
                  width: "100%",
                  height: "100%",
                  margin: "0 auto",
                  marginTop: "1.5rem",
                }}
                text="No more restaurants! Choose one from your matches or start a new group!"
              ></AlertPage>
            )}
          {restaurants?.map((restaurant, index) => {
            if (currentIndex - index > 1) {
              return;
            }
            return (
              <TinderCard
                ref={childRefs[index]}
                key={index}
                className="swipe"
                onSwipe={dir => {
                  swipe(dir, index);
                }}
                preventSwipe={["up", "down"]}
              >
                <RestaurantCard
                  index={index}
                  currentIndex={currentIndex === index}
                  restaurant={restaurant}
                  onOffScreen={onOffScreen}
                />
              </TinderCard>
            );
          })}
        </div>
        <div className="button-container">
          <Button
            text={
              <FontAwesomeIcon
                icon={faTimes}
                style={{ width: "2rem", height: "2rem" }}
              />
            }
            onClick={() => buttonSwipe("left")}
            style={{
              width: "4rem",
              height: "4rem",
              borderRadius: "4rem",
              backgroundColor: "hsl(209, 20%, 25%)",
            }}
            ariaLabel="swipe left"
          />
          <Button
            text={
              <FontAwesomeIcon
                icon={faHeart}
                style={{ width: "2rem", height: "2rem" }}
              />
            }
            onClick={() => buttonSwipe("right")}
            style={{
              width: "4rem",
              height: "4rem",
              borderRadius: "4rem",
              backgroundColor: "#FF7171",
            }}
            ariaLabel="swipe right"
          />
        </div>
        <p className="room-code">
          Room code: <span>{sessionToken.toString()}</span>
        </p>
        <Collapsible
          trigger={
            <div className="trigger-container">
              <p>Matches</p>
              <p className="badge" key={matches?.length}>
                {matches?.length}
              </p>
            </div>
          }
          triggerStyle={{
            minWidth: "100%",
            height: "auto",
          }}
          easing="ease-in-out"
          transitionTime={200}
        >
          {matches?.map(match => {
            return (
              <a
                className="yelp-match-link"
                key={match.name}
                href={`https://yelp.com/biz/${match.yelp_id}`}
                rel="noopener"
                target="_blank"
                style={{ color: state.theme === "light" ? "black" : "white" }}
              >
                <div className="row-container">
                  <p key={match.name}>{match.name}</p>
                  <FontAwesomeIcon
                    icon={faExternalLinkAlt}
                    style={{ width: "18px", height: "18px" }}
                  />
                </div>
              </a>
            );
          })}
        </Collapsible>
      </section>
    </>
  );
};

export default session;
